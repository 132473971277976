export const GET_ROLES = 'GET_ROLES';
export const SET_ROLES = 'SET_ROLES';
export const GET_CATANSTITUTION = 'GET_CATANSTITUTION';
export const SET_CATANSTITUTION = 'SET_CATANSTITUTION';
export const GET_CURRENT_PROPOSALS = 'GET_CURRENT_PROPOSALS';
export const SET_CURRENT_PROPOSALS = 'SET_CURRENT_PROPOSALS';
export const GET_CURRENT_PROPOSALS_VOTES = 'GET_CURRENT_PROPOSALS_VOTES';
export const SET_CURRENT_PROPOSALS_VOTES = 'SET_CURRENT_PROPOSALS_VOTES';

export const GET_KEEPER_OF_THE_CATANSTITUTION_VOTES = 'GET_KEEPER_OF_THE_CATANSTITUTION_VOTES';
export const SET_KEEPER_OF_THE_CATANSTITUTION_VOTES = 'SET_KEEPER_OF_THE_CATANSTITUTION_VOTES';
export const SET_VOTE_FOR_KEEPER_OF_THE_CATANSTITUTION = 'SET_VOTE_FOR_KEEPER_OF_THE_CATANSTITUTION';
export const GET_RULER_OF_CATAN_VOTES = 'GET_RULER_OF_CATAN_VOTES';
export const SET_RULER_OF_CATAN_VOTES = 'SET_RULER_OF_CATAN_VOTES';
export const SET_VOTE_FOR_RULER_OF_CATAN = 'SET_VOTE_FOR_RULER_OF_CATAN';

export const SEND_VOTER_TOKEN = 'SEND_VOTER_TOKEN';
export const MINT_TOKEN = 'MINT_TOKEN';
export const BURN_TOKEN = 'BURN_TOKEN';
export const PROPOSE_AMENDMENT = 'PROPOSE_AMENDMENT';
export const RESOLVE_AMENDMENT = 'RESOLVE_AMENDMENT';
export const VOTE_ON_PROPOSED_AMENDMENT = 'VOTE_ON_PROPOSED_AMENDMENT';
export const GET_BALANCE = 'GET_BALANCE';
export const SET_BALANCE = 'SET_BALANCE';
export const GET_BALANCES = 'GET_BALANCES';
export const SET_BALANCES = 'SET_BALANCES';

export const GET_USER_NAMES = 'GET_USER_NAMES';
export const SET_USER_NAMES = 'SET_USER_NAMES';
export const SET_USER_NAME = 'SET_USER_NAME';

export const UPDATE_TROPHIES = 'UPDATE_TROPHIES';
export const GET_TROPHY_HOLDERS = 'GET_TROPHY_HOLDERS';
export const SET_TROPHY_HOLDERS = 'SET_TROPHY_HOLDERS';

export const GET_RECORDED_TWO_PLAYER_GAMES = 'GET_RECORDED_TWO_PLAYER_GAMES';
export const GET_RECORDED_THREE_PLAYER_GAMES = 'GET_RECORDED_THREE_PLAYER_GAMES';
export const GET_RECORDED_FOUR_PLAYER_GAMES = 'GET_RECORDED_FOUR_PLAYER_GAMES';
export const GET_RECORDED_FIVE_PLAYER_GAMES = 'GET_RECORDED_FIVE_PLAYER_GAMES';
export const GET_RECORDED_SIX_PLAYER_GAMES = 'GET_RECORDED_SIX_PLAYER_GAMES';

export const ADD_RECORDED_TWO_PLAYER_GAME = 'ADD_RECORDED_TWO_PLAYER_GAME';
export const ADD_RECORDED_THREE_PLAYER_GAME = 'ADD_RECORDED_THREE_PLAYER_GAME';
export const ADD_RECORDED_FOUR_PLAYER_GAME = 'ADD_RECORDED_FOUR_PLAYER_GAME';
export const ADD_RECORDED_FIVE_PLAYER_GAME = 'ADD_RECORDED_FIVE_PLAYER_GAME';
export const ADD_RECORDED_SIX_PLAYER_GAME = 'ADD_RECORDED_SIX_PLAYER_GAME';
export const RECORD_TWO_PLAYER_GAME = 'RECORD_TWO_PLAYER_GAME';
export const RECORD_THREE_PLAYER_GAME = 'RECORD_THREE_PLAYER_GAME';
export const RECORD_FOUR_PLAYER_GAME = 'RECORD_FOUR_PLAYER_GAME';
export const RECORD_FIVE_PLAYER_GAME = 'RECORD_FIVE_PLAYER_GAME';
export const RECORD_SIX_PLAYER_GAME = 'RECORD_SIX_PLAYER_GAME';

export const SET_GAME_PLAYER_FILTERS = 'SET_GAME_PLAYER_FILTERS';
export const SET_GAME_PLAYER_FILTERS_OPTIONS = 'SET_GAME_PLAYER_FILTERS_OPTIONS';
export const SET_GAME_VARIATION_FILTERS = 'SET_GAME_VARIATION_FILTERS';

export const CONNECT_TO_CHAIN = 'CONNECT_TO_CHAIN';
export const SETUP = 'SETUP';
